import { DaDataAddressSuggestion } from "react-dadata";

import { JbVacancyState, UserType, VacancyType } from "graphql/types/types";
import { AvitoState } from ".";
import { transformToDaDataAddressSuggestion } from "common/helpers/transformDaData";
import { getContacts, getDescription } from "../helpers";
import { AVITO_LOGO } from "common/const/avito";
import { Type } from "components/UI/Notifications/Notification";

export const getInitialState = (vacancy: VacancyType): AvitoState => ({
  title: vacancy.additionalAvito?.title || vacancy.name || "",
  description: vacancy.description ?? "",
  shortDescription: vacancy.shortDescription ?? "",
  terms: vacancy.terms ?? "",
  claims: vacancy.claims ?? "",
  platformTags: vacancy.platformTags ?? "",
  employment: vacancy.additionalAvito?.employment ?? "",
  experience: vacancy.additionalAvito?.experience ?? "",
  salaryFrom: vacancy.salary.from ?? 0,
  salaryTo: vacancy.salary.to ?? 0,
  managerId: vacancy?.manager?.id,
  address: transformToDaDataAddressSuggestion(vacancy.address),
  schedule: vacancy.additionalAvito?.schedule ?? "",
  profession: vacancy.additionalAvito?.profession ?? "",
  registrationMethod: vacancy.additionalAvito?.registrationMethod ?? [],
  bonuses: vacancy.additionalAvito?.bonuses ?? [],
  workFormat: vacancy.additionalAvito?.workFormat ?? [],
});

export const getAvitoAddress = (addressSuggestion: DaDataAddressSuggestion) => {
  const { data } = addressSuggestion || {};

  return {
    area: data?.area || "",
    region: data?.region || "",
    street: data?.street || "",
    house: data?.house || "",
    locality: data.city || data.settlement || "",
  };
};

type PayloadOptions = {
  managerList: UserType[];
  age: VacancyType["age"];
};

export const getPayload = (
  {
    description,
    shortDescription,
    terms,
    claims,
    salaryFrom,
    salaryTo,
    managerId,
    address,
    platformTags,
    ...props
  }: AvitoState,
  { managerList, age }: PayloadOptions
) => ({
  age,
  description: getDescription({
    description,
    shortDescription,
    terms,
    claims,
    platformTags,
  }),
  location: {
    address: getAvitoAddress(address),
  },
  salary: { from: Number(salaryFrom), to: Number(salaryTo) },
  contacts: {
    name: getContacts(managerId, managerList).fullName,
    phone: getContacts(managerId, managerList).phone,
  },
  imageUrl: AVITO_LOGO,
  ...props,
});

const ARCHIVE = [JbVacancyState.Archived, JbVacancyState.Archiving];
const PUBLICATION = [JbVacancyState.Published, JbVacancyState.Publishing];

export const getButtontext = (state?: JbVacancyState | null) => {
  if (!state) return "Опубликовать";

  if (PUBLICATION.includes(state)) return "Обновить";

  if (ARCHIVE.includes(state)) return "Подменить объявление";

  return "Опубликовать";
};

const IN_PROGRESS = [
  JbVacancyState.Archiving,
  JbVacancyState.Editing,
  JbVacancyState.Publishing,
  JbVacancyState.Created,
];
const ERROR = [JbVacancyState.Blocked, JbVacancyState.Rejected];

export const getNotificationTypeByState = (state: JbVacancyState): Type => {
  return (
    (IN_PROGRESS.includes(state) && "info") ||
    (ERROR.includes(state) && "error") ||
    (JbVacancyState.Published === state && "success") ||
    (JbVacancyState.Archived === state && "warning") ||
    "error"
  );
};
